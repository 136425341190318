@import 'variables';
@import 'icons';
@import 'animate.min.css';


@font-face {
	font-family: "pega-core";
	src: url("~/fonts/pega-icons.ttf") format("truetype"),
		url("~/fonts/pega-icons.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
body{
	background-color: $app-bkg;
	.container {
		max-width: calc(100% - 30px);
	}
}

a{
	color: $interactive
}

.pi-btn-primary > button{
	border-radius: 50px;
	background-color: $interactive;
	&:hover {
		background-color: $interactive-hover;
		color: $interactive;
		border-color: $interactive-hover;
	}
}
.bg-blue {
  background-color: $app-header;
}
.bg-white {
  background-color: white;
}
.animated {
		@for $i from 1 through 50 {
		&:nth-child(#{$i}) {
			animation-delay: 100ms * $i;
		}
	}
}
.margin-auto {
	margin: auto;
}

.btn.btn-link.btn-secondary {
	background-color: transparent;
	border: none;
	font-size: $default-text;
	line-height: 1em;
	color: $interactive;
	padding: 10px 0px;
}

.blue-border-top {
	border-top: $app-header 10px solid;
}
