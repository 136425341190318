// colors
$app-header: #1F2555;
$app-bkg: #E8EAEF;
$secondary-bkg: #F2F3F4;
$grey: #585B73;
$light-grey: #CFD1E6;
$light-yellow: #FFECCC;
$light-green: #DAF2E3;
$light-purple: #D9DDFF;
$light-olive: #EEF2DA;
$content: #10132E;
$interactive: #295ED9;
$interactive-hover: #F3F6FC;
// typography
$default-text: 16px;
$secondary-text: 14px;
$helper-text: 12px;

